export const leftText = [
  {
    text: "The AFI Conservatory is a non-profit, 501 c3 organization with a mandate to champion the moving image as an art form. As such, AFI was most recently ranked at No.1 among all the film schools in the country by The Hollywood Reporter.",
  },
  {
    text: "Established in 1969, the two-year Master of Fine Arts program provides training in six filmmaking disciplines: Directing, Cinematography, Screenwriting, Production Design Editing and Producing. Admission to AFI Conservatory is highly selective, with a maximum of only 140 graduates per year. The AFI Conservatory is dedicated to celebrating excellence in the art form and to creating national education initiatives that inspire artists and audiences alike. A dedicated group of working professionals from the film and television communities serve as mentors in a hands-on, production-based environment nurturing the talents of tomorrow's storytellers. Over half of the incoming students are women, and nearly half are filmmakers of color. As students of this world-renowned organization, your donation is received directly by the AFI Conservatory and is 100% tax deductible. Once processed, our team receives your exceptionally kind donation direct to our production account to be used immediately toward locations, cast and crew fair pay rates, equipment and camera rentals, insurance and festival preparation upon final cut of the film.",
  },
];

export const rightText = [
  {
    category: "SCREENWRITING",
    people: [
      {
        name: "Brad Falchuk",
        projects: "American Horror Story, Glee",
      },
      {
        name: "Susannah Grant",
        projects: "Erin Brockovich, The Soloist",
      },
    ],
  },
  {
    category: "PRODUCING",
    people: [
      {
        name: "Steve Golin",
        projects: "Spotlight, The Revenant ",
      },
      {
        name: "Pieter Jan Brugge",
        projects: "Heat, The Insider",
      },
      {
        name: "Daniel Dreifuss",
        projects: "All The Quiet on the Western Front",
      },
    ],
  },
  {
    category: "PRODUCTION DESIGN",
    people: [
      {
        name: "Sharon Seymour",
        projects: "Argo, The Town, Bad Santa",
      },
      {
        name: "Andrew Max Cahn",
        projects: "The Hunger Games, The Hangover",
      },
      {
        name: "Todd Cherniawsky",
        projects: "The Last Jedi, Star Trek: Picard",
      },
    ],
  },
  {
    category: "DIRECTING",
    people: [
      {
        name: "David Lynch",
        projects: "Twin Peaks, Blue Velvet",
      },
      {
        name: "Patty Jenkins",
        projects: "Wonder Woman, Monster",
      },
      {
        name: "Darren Aronofsky",
        projects: "Black Swan, Requiem For A Dream",
      },
      {
        name: "Todd Field",
        projects: "Eyes Wide Shut, TÁR",
      },
      {
        name: "Terrence Malick",
        projects: "The Tree of Life,  The red Thin Line ",
      },
      {
        name: "Paul Schrader",
        projects: "First Reformed, Taxi Driver, Raging Bull ",
      },
      {
        name: "Ari Aster",
        projects: "Hereditary, Midsommar, Beau Is Afraid",
      },
    ],
  },
  {
    category: "CINEMATOGRAPHY",
    people: [
      {
        name: "Janusz Kaminski",
        projects: "Schindler’s List, Bridge of Spies",
      },
      {
        name: "Robert Richardson",
        projects: "Hugo, The Hateful Eight ",
      },
      {
        name: "Wally Pfister",
        projects: "Batman Begins, Inception, Memento",
      },
      {
        name: "Matthew Libatique",
        projects: "Requiem For a Dream, Black Swan, Mother!",
      },
      {
        name: "Rachel Morrison",
        projects: "Mudbound, Black Panther",
      },
    ],
  },
  {
    category: "EDITING",
    people: [
      {
        name: "Affonso Goncalves",
        projects: "Beasts of the Southern Wild, Carol",
      },
      {
        name: "Christopher Gay",
        projects: "12 Monkeys, Mad Men",
      },
      {
        name: "Sarah Broshar",
        projects: "The Post, West Side Story, The Fabelmans",
      },
    ],
  },
];
