import React from "react";
import { leftText, rightText } from "../data/pages/afi";
import { StaticImage } from "gatsby-plugin-image";
import "../styles/pages/afi.scss";

const AFI = () => {
  return (
    <div className="rw-afi">
      <StaticImage
        src="../images/afi/background2.png"
        layout="fullWidth"
        alt="afi background"
        className="rw-afi__background"
      />
      <div className="rw-afi__inner">
        <div className="rw-afi__left">
          {leftText.map((text) => {
            return <p>{text.text}</p>;
          })}
          <StaticImage
            alt="afi"
            placeholder="blurred"
            src="../images/homepage/afi.png"
          />
        </div>
        <div className="rw-afi__right">
          <h5>NOTABLE ALUMNI</h5>
          {rightText.map((text) => {
            const { category, people } = text;
            return (
              <div key={category} className="rw-afi__right__category">
                <h5>{category}</h5>
                {people.map((person) => {
                  const { name, projects } = person;
                  return (
                    <p key={name} className="rw-afi__right__category__person">
                      <strong>{name}</strong>
                      {" - "}
                      {projects}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AFI;
